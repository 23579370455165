<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-form
      ref="teamInviteNameEmail"
      v-model="formValidated"
    >
      <v-row
        dense
        class="pa-4"
      >
        <v-col cols="12">
          <v-alert type="info">
            {{ $t('usernameBeware') }}
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :disabled="processing || checkingUser"
            name="name"
            :label="$t('fullName') + ' ' + $t('username') + ' *'"
            v-model="displayName"
            filled
            dense
            required
            :rules="[rules.required, rules.fullName]"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :disabled="processing || checkingUser"
            name="email"
            :label="$t('email') + ' *'"
            v-model="email"
            filled
            dense
            required
            :rules="[rules.required, rules.email]"
          />
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col
          cols="12"
          class="px-0 pt-0"
        >
          <v-divider />
        </v-col>
      </v-row>
      <v-row
        justify="space-between"
        dense
      >
        <v-col
          cols="12"
          md="auto"
        >
          <v-btn
            block
            large
            color="error"
            text
            :disabled="processing || checkingUser"
            @click="abort"
          >
            {{ $t('abort') }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="auto"
        >
          <v-btn
            block
            large
            color="primary"
            :loading="processing || checkingUser"
            :disabled="processing || checkingUser || !formValidated"
            @click="nextStep"
          >
            {{ $t('next') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
export default {
	name: "TeamInviteNameEmail",
	data() {
		return {
			displayName: null,
			email: null,
			formValidated: false,
			userNameExists: false,
			userEmailExists: false,
			userAlreadyInTeam: false,
			checkingUser: false
		}
	},
	computed: {
		processing() {
			return this.$store.state.isProcessingTeamInvite
		},
		rules() {
			return this.$store.getters.rules
		},
		currentTeam() {
			return this.$store.state.team
		}
	},
	methods: {
		abort() {
			this.$emit('close')
		},
		async nextStep() {
			this.checkingUser = true
      let emailOrUsernameExists = false

      try {
  			this.userNameExists = await this.$store.dispatch('userExistsByDisplayName', this.displayName)
        this.userEmailExists = await this.$store.dispatch('userExistsByEmail', this.email)        
  
        // This code is checking if either the username or email already exists
        // If the user is already in the team, we don't want to add them again
        // If there is a user with the same email and username, we want to add them to the team
        // If there ie a user with the same email but different username, we want to show an error
        // If there is a user with the same username but different email, we want to show an error
        // This is because both fullname and e-mail are unique in Drupal
        if(this.userNameExists || this.userEmailExists) {
          if(this.currentTeam.some(member => member.user.email === this.email || member.user.displayName === this.displayName)) {            
            this.userAlreadyInTeam = true
          } else if(this.userEmailExists && this.userNameExists) {            
            const users = await this.$store.dispatch('searchUser', this.email)

            if(users[0]?.displayName !== this.displayName) {
              emailOrUsernameExists = true
            } // else continue adding an existing user to your team
          } else {
            emailOrUsernameExists = true
          }
        }
      } catch (error) {
        console.error('[TeamInviteNameEmail.nextStep] error', error)
      }

      this.checkingUser = false
      this.$emit('next', {
        displayName: this.displayName,
        email: this.email,
        userNameExists: this.userNameExists,
        userEmailExists: this.userEmailExists,
        userAlreadyInTeam: this.userAlreadyInTeam,
        emailOrUsernameExists
      })
		}
	}
}
</script>

<style scoped>

</style>